<template>
  <div class="coor_container" v-loading="loading">
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">联系人名称</span>
          <el-input
            class="searchInput"
            placeholder="请输入联系人名称"
            v-model="searchValue.contactName"
            clearable
            @keyup.enter.native="loadData()"
          />
        </el-col>
        <el-col :span="8">
          <span class="searchText">ID</span>
          <el-input
            class="searchInput"
            placeholder="请输入ID"
            v-model="searchValue.id"
            clearable
            oninput="value=value.replace(/[^0-9]/g,'')"
            @keyup.enter.native="loadData()"
          />
        </el-col>
        <el-col :span="4">
          <el-button type="info" @click="resetData()">重置</el-button>
          <el-button type="primary" @click="loadData()"> 搜索</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>数据列表</span>
        <el-button type="success" style="float: right; " @click="handleAdd">添加</el-button>
      </div>
      <div style="margin: 14px 7px" v-if="$store.getters['user/isAllArea']">
        <!-- <span class="text">活动对应城市</span>
        <el-select class="city" placeholder="请选择城市" @change="loadData()" v-model="areaId">
          <el-option v-if="areaId === -1" label="全国" :value="-1"></el-option>
          <el-option
            v-for="item in $store.getters['user/userAreaInfoListWithoutGlobal']"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select> -->
      </div>
      <el-table :data="listData.tableData" style="width: 100%">
        <el-table-column align="center" prop="id" label="序号"> </el-table-column>
        <el-table-column align="center" prop="wxQrCodeUrl" label="微信二维码">
          <template slot-scope="scope">
            <div class="wrapper">
              <img class="avatar" :src="scope.row.wxQrCodeUrl | thumbSmall" />
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="contactName" label="联系人"> </el-table-column>
        <el-table-column align="center" label="地区">
          <template slot-scope="{ row }">
            <p>{{ row.areaName }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="remark" label="备注"> </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="{ row }">
            <el-button @click="handleEdit(row)" type="text" size="small"> 编辑</el-button>
            <el-button
              @click="delItem(row, '删除提示', '确定删除该商户合作方式吗？')"
              style="color: red;"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
      :close-on-click-modal="false"
      title="添加商务合作"
      :visible="showContent"
      width="40%"
      custom-class="coor_dialog"
      @close="handleCancel"
    >
      <el-form ref="form" class="setting" :rules="rules" :model="form" label-width="200px">
        <el-form-item label="微信二维码" prop="wxQrCodeUrl">
          <bm-upload :reduceResolution="true" :value="form.wxQrCodeUrl" @input="updateProductImage"></bm-upload>
          <div style="font-size: 12px;color: #999;">
            建议尺寸300*300
          </div>
        </el-form-item>
        <el-form-item label="联系人" prop="contactName">
          <el-input v-model="form.contactName" class="com_input" placeholder="请输入联系人" />
        </el-form-item>
        <el-form-item label="选择地区" prop="areaId">
          <el-select placeholder="选择地区" v-model="form.areaId">
            <el-option v-if="form.areaId === -1" label="全国" :value="-1"></el-option>
            <el-option v-for="item in options" :key="item.id" :label="item.areaName" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="请填写备注" prop="remark">
          <el-input type="textarea" :rows="2" class="com_input" placeholder="请输入内容" v-model="form.remark">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button round @click="handleCancel">取消</el-button>
        <el-button round type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import createData, { loadData, delItem, freezeData, resetData } from '@/plugin/page-helper'
import { mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      listData: createData(),
      options: [],
      showContent: false,
      form: {
        wxQrCodeUrl: '',
        contactName: '',
        areaId: '',
        remark: ''
      },
      rules: {
        wxQrCodeUrl: [
          {
            required: true,
            message: '请上传微信二维码',
            trigger: 'change'
          }
        ],
        contactName: [
          {
            required: true,
            message: '联系人不能为空',
            trigger: 'blur'
          }
        ],
        areaId: [
          {
            required: true,
            message: '请选择地区',
            trigger: 'change'
          }
        ]
      },
      searchValue: {
        contactName: '',
        id: ''
      },
      currentStatus: 0,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      allAreaList: 'user/allAreaList',
      currentAreaId: 'user/currentAreaId'
    })
  },
  methods: {
    loadData,
    delItem,
    freezeData,
    resetData,
    onLoadData(data) {
      return this.$api.user
        .user('businessCooperationList', {
          params: {
            ...this.searchValue,
            ...data
          }
        })
        .then(res => {
          this.listData.data = res
        })
    },
    getAreaData() {
      return this.$api.user.user('listForBusinessCooperation').then(res => {
        this.options = res
      })
    },
    onDelItem(row) {
      return this.$api.user.user('delBusinessCooperation', row.id).then(_ => {
        this.getAreaData()
        return true
      })
    },
    handleAdd() {
      this.getAreaData().then(_ => {
        this.showContent = true
        this.currentStatus = 0
      })
    },
    handleEdit(data) {
      this.showContent = true
      this.form = data
      this.currentStatus = 1
      this.options.unshift({
        id: data.areaId,
        areaName: data.areaName
      })
      console.log(this.form, this.options)
    },
    handleCancel() {
      this.form = {
        wxQrCodeUrl: '',
        contactName: '',
        areaId: '',
        remark: ''
      }
      this.showContent = false
    },
    handleConfirm() {
      this.$refs.form.validate((valid, validInfo) => {
        if (!valid) {
          this.$message.error(validInfo[Object.keys(validInfo)[0]][0].message)
          return
        }
        let url = this.currentStatus === 0 ? 'addBusinessCooperation' : 'editBusinessCooperation'
        return this.$api.user
          .user(url, {
            id: this.form.id || 0,
            ...this.form
          })
          .then(res => {
            this.$message.success('操作成功')
            this.handleCancel()
            this.loadData()
            this.getAreaData()
          })
          .catch(err => {
            this.$message.error(err.msg || '操作失败')
          })
      })
    },
    updateProductImage(value) {
      this.form.wxQrCodeUrl = value
      this.$refs.form.validateField('wxQrCodeUrl')
    }
  },
  created() {
    this.loadData()
    this.freezeData(this.searchValue)
  }
}
</script>

<style lang="less" scoped>
.coor_container {
  .footer {
    text-align: center;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .avatar {
      display: inline-block;
      vertical-align: middle;
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }
}
.coor_dialog {
  .com_input {
    width: 200px;
  }
}
</style>
